<template>
  <div>
      <h2>Dashboard</h2>
      <a-row :gutter="16" class="mt-5">
        <a-col :span="6">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center">
            <div class="bg-primary rounded p-3 my-3 ml-3 mr-3 font-size-28 flex-shrink-0 text-white">
               <a-icon type="usergroup-add" />
            </div>
            <div>
              <div class="font-size-16 font-weight-bold">Pegawai Aktif</div>
              <div class="font-size-16 text-uppercase">161</div>
            </div>
          </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center">
            <div class="bg-danger rounded p-3 my-3 ml-3 mr-3 font-size-28 flex-shrink-0 text-white">
              <a-icon type="usergroup-delete" />
            </div>
            <div>
              <div class="font-size-16 font-weight-bold">Pegawai Tidak Aktif</div>
              <div class="font-size-16 text-uppercase">5</div>
            </div>
          </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center">
            <div class="bg-success rounded p-3 my-3 ml-3 mr-2 font-size-28 flex-shrink-0 text-white">
              <a-icon type="line-chart" />
            </div>
            <div>
              <div class="font-size-16 font-weight-bold">Persentase Tepat Waktu</div>
              <div class="font-size-16 text-uppercase">85%</div>
            </div>
          </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="card">
            <div class="d-flex flex-wrap align-items-center">
            <div class="bg-warning rounded p-3 my-3 ml-3 mr-3 font-size-28 flex-shrink-0 text-white">
              <a-icon type="exception" />
            </div>
            <div>
              <div class="font-size-16 font-weight-bold">Persetujuan Cuti</div>
              <div class="font-size-16 text-uppercase">11</div>
            </div>
          </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" class="">
        <a-col :span="16">
          <div class="card">
            <div class="card-header card-header-flex">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div>
                  <h2 class="font-size-18">Laporan Kehadiran Bulanan</h2>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <div class="">
                  <span class="mr-2">
                    <span class="kit__utils__donut kit__utils__donut--primary"></span>
                    Hadir
                  </span>
                  <span class="mr-2">
                    <span class="kit__utils__donut kit__utils__donut--danger"></span>
                    Absen
                  </span>
                  <span class="mr-2">
                    <span class="kit__utils__donut kit__utils__donut--yellow"></span>
                    Cuti
                  </span>
                </div>
                <div>
                  <a-select
                  class="mr-3"
                    size="large"
                    default-value="January"
                  >
                    <a-select-option key="January">January</a-select-option>
                    <a-select-option key="February">February</a-select-option>
                    <a-select-option key="March">March</a-select-option>
                    <a-select-option key="April">April</a-select-option>
                  </a-select>
                  <a-select
                    size="large"
                    default-value="2021"
                  >
                    <a-select-option key="2019">2019</a-select-option>
                    <a-select-option key="2020">2020</a-select-option>
                    <a-select-option key="2021">2021</a-select-option>
                  </a-select>
                </div>
              </div>
              <vue-chartist
                class="height-300 mt-4"
                type="Line"
                :data="weekChartistData"
                :options="weekChartistOptions"
              />
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card">
            <div class="card-header card-header-flex">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div>
                  <h2 class="font-size-18">Mengajukan Cuti</h2>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="list-unstyled">
                <li :class="$style.item">
                  <div :class="$style.itemHead" class="mb-3">
                    <!-- <div :class="$style.itemPic">
                      <img src="resources/images/avatars/1.jpg" alt="Mary Stanform"/>
                    </div> -->
                    <div class="mr-2">
                      <div>Jamie Rockstar</div>
                      <div class="text-muted">11 January 2021</div>
                    </div>
                    <div class="text-warning ml-auto">Pending</div>
                  </div>
                  <p class="mb-4">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                    has been the industry&apos;s Lorem ipsum dolor sit amet. standard dummy text ...
                  </p>
                </li>
                <li :class="$style.item">
                  <div :class="$style.itemHead" class="mb-3">
                    <!-- <div :class="$style.itemPic">
                      <img src="resources/images/avatars/2.jpg" alt="Mary Stanform"/>
                    </div> -->
                    <div class="mr-2">
                      <div>Alex Kasie</div>
                      <div class="text-muted">28 December 2020</div>
                    </div>
                    <div class="text-success ml-auto">Approved</div>
                  </div>
                  <p class="mb-4">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                    has been the industry&apos;s standard dummy text ...
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </a-col>
      </a-row>
  </div>
</template>

<script>
import VueChartist from 'v-chartist'
const weekChartistData = {
  labels: [
    'Week 1',
    'Week 2',
    'Week 3',
    'Week 4',
  ],
  series: [
    [
      5,
      2,
      4,
      2,
    ],
    [
      3,
      1,
      5,
      4,
    ],
    [
      0,
      4,
      3,
      5,
    ],
  ],
}
const weekChartistOptions = {
  fullWidth: true,
  showArea: false,
  chartPadding: {
    right: 30,
    left: 0,
  },
}
export default {
  components: {
    'vue-chartist': VueChartist,
  },
  data() {
    return {
      weekChartistData,
      weekChartistOptions,
    }
  },
}
</script>

<style lang='scss' module>
  @import './style.module.scss';
</style>
